@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  max-width: $component-max-width;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 0 60px 0px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0px 20px 0px;
  }
  @include media-breakpoint-up(xl) {
    padding: 0px $component-padding-x 0px;
  }

  --maskRight: linear-gradient(to right, 
    rgba(0,0,0, 1) 0%,
    rgba(0,0,0, 1) 93%,
    rgba(0,0,0, 0) 100%
  );

  --maskLeft: linear-gradient(to left, 
    rgba(0,0,0, 1) 0%,
    rgba(0,0,0, 1) 93%,
    rgba(0,0,0, 0) 100%
  );

  :global {
    .slick-disabled {
      display: none;
    }

    .slick-list {
      @include media-breakpoint-up(md) {
        -webkit-mask: var(--maskLeft), var(--maskRight);
        mask: var(--maskLeft), var(--maskRight);
        -webkit-mask-composite: intersect;
        mask-composite: intersect;
      }
    }

    .slick-slide {
      & > div {
        margin: 0 12px;
    
        @include media-breakpoint-up(md) {
          margin: 0 16px;
        }
      }
    }
  }

  .subHeader {
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 0 0 15px 0;
    }
  }
  
  .sliderContainer {
    @include media-breakpoint-up(md) {
      margin: 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 25px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  .sliderItem {
    position: relative;
    aspect-ratio: 235 / 350;
    
    a {
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    picture {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 8px;
    }

    .text {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      min-height: 41.5%;
      padding: 5px;
      border-radius: 0 0 8px 8px;
      
      .textBorder {
        text-align: center;
        color: $white;
        text-shadow: 0px 0px 6.776552677154541px rgba($black, 0.50);
        font-family: $harrybeastregular;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 4%;
        left: 2%;
        right: 2%;
        top: 4%;
        padding: 0 9%;
        line-height: 120%;
        font-size: 22px;
        
        @include media-breakpoint-up(sm) {
          font-size: 28px;
        }
        @include media-breakpoint-up(ss) {
          font-size: 30px;
        }
        @include media-breakpoint-up(md) {
          font-size: 12px;
        }
        @include media-breakpoint-up(xm) {
          font-size: 16px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 22px;
        }

        span {
          padding: 0;
          margin: 0;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .prevArrow {
    position: absolute;
    top: 50%;
    left: -40px;
    z-index: 10;
    transform: translate(0, -50%);
  }

  .nextArrow {
    position: absolute;
    top: 50%;
    right: -40px;
    z-index: 10;
    transform: translate(0, -50%);
  }

  $darkenAmount1: 40%;
  $darkenAmount2: 10%;

  .addTextBackground {
    .textBorder { 
        border-image-slice: 30 30 30 30;
        border-image-width: 35px 35px 35px 35px;
        border-image-outset: 0px 0px 0px 0px;
        border-image-repeat: stretch stretch;
        border-image-source: url('/images/components/FlexibleCarousel/border.svg');
    }
  }

  &.row1 {
    .sliderItem {      
      &.addTextBackground {
        .text {
          background: linear-gradient(180deg, $homeRow1c,  $homeRow1d);
        }
      }
    }
  }
  &.row2 {
    .sliderItem {
      &.addTextBackground {
        .text {
          background: linear-gradient(180deg, $homeRow2c,  $homeRow2d);
        }
      }
    }
  }
  &.row3 {
    .sliderItem {
      &.addTextBackground {
        .text {
          background: linear-gradient(180deg, $homeRow3c,  $homeRow3d);
        }
      }
    }
  }
  &.row4 {
    .sliderItem {
      &.addTextBackground {
        .text {
          background: linear-gradient(180deg, $homeRow4c,  $homeRow4d);
        }
      }
    }
  }
  &.row5 {
    .sliderItem {
      &.addTextBackground {
        .text {
          background: linear-gradient(180deg, $homeRow5c,  $homeRow5d);
        }
      }
    }
  }
  &.row0 {
    .sliderItem {
      &.addTextBackground {
        .text {
          background: linear-gradient(180deg, $homeRow0c,  $homeRow0d);
        }
      }
    }
  }
}

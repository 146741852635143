@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-width: $component-max-width;
  padding: 0 $component-padding-x;
  box-sizing: border-box;
  
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    @include media-breakpoint-up(md) {
      width: 50%;
    }

    h2 {
      color: $snow;
      margin: 20px 0 0;
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0;
      text-transform: uppercase;
      
      @include media-breakpoint-up(md) {
        margin: 0;
        font-size: 40px;
        line-height: 44px;
      }
    }

    p {
      width: 80%;
      font-family: $sofiapro;
      color: $white;
      margin: 20px 0;
      line-height: 120%;
      font-size: 14px;
      
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
    
    button {
      margin-bottom: 25px;
      
      @include media-breakpoint-up(md) {
        margin-bottom: 0px;
      }
    }
  }

  .image {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    margin: 0 auto;
    
    @include media-breakpoint-up(md) {
      width: 50%;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  @include media-breakpoint-up(md) {
    &.left {
      .cta {
        order: 1;
      }

      .image {
        order: 2;
      }
    }

    &.right {
      .cta {
        order: 2;
      }

      .image {
        order: 1;
      }
    }
  }
}

@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.root {
  width: 100%;
  aspect-ratio: 530 / 560;
  max-width: 530px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.ctaContent {
  position: absolute;
  bottom: 0;
  text-align: center;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  box-sizing: border-box;

  .header {
    font-family: $harrybeastregular;
    font-size: 39px;
    line-height: 46px;
    letter-spacing: 0.3px;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 20px;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 32px;
      padding: 0 20px;
      margin-bottom: 15px;
    }

    @include media-breakpoint-down(md) {
      font-size: 28px;
      line-height: 30px;
      padding: 0 20px;
    }
  }

  .cta {
    margin-bottom: 14px;
  }
}
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.arrow {
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .next {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('/images/svg/chevron_next.svg') no-repeat 50% 50%;
    background-size: contain;
  }

  .prev {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('/images/svg/chevron_prev.svg') no-repeat 50% 50%;
    background-size: contain;
  }
  
  &.circleBorder {
    border: solid 1px $alto-white;
    border-radius: 50%;
  }
}

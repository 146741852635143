@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.image {
  display: block;
  width: 100%;
}

.imageWrapper {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to top,
      $darkNavy,
      rgba($darkNavy, 0) 20%
    );
  }
}

.footer {
  background-image: url('./imgs/content-tile-footer.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: $content-file-footer-height;
}

.info {
  padding: 5px 13px;
  background-color: $darkNavy;
}

.title {
  font-family: $sofiapro;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: $white;
  min-height: 66px;
  overflow: hidden;
  line-clamp: 4;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.eyebrow {
  font-family: $sofiapro;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: $snow;
  text-transform: uppercase;
  background: $darkMagic;
  border-radius: 12px;
  padding: 5px 8px;
  width: fit-content;
  position: relative;
  top: -18px;
  @include media-breakpoint-down(sm) {
    top: -13px;
  }
}


.compact {
  .info {
    min-height: 97px;
  }

  @include media-breakpoint-down(lg) {
    .info {
      min-height: 110px;
    }

    .eyebrow {
      font-size: 12px;
      line-height: 19px;
    }

    .title {
      font-size: 13px;
      line-height: 15.6px;
      line-clamp: 4;
      text-overflow: ellipsis;
      display: -webkit-box;
      min-height: 0;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  @include media-breakpoint-down(md) {
    * {
      line-height: 14px;
    }

    .info {
      min-height: 85px;
    }

    .title {
      font-size: 12px;
      margin-bottom: 7px;
      line-clamp: 4;
      text-overflow: ellipsis;
      display: -webkit-box;
      min-height: 0;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .eyebrow {
      font-size: 10px;
    }
  }
  @include media-breakpoint-down(sm) {
    .info {
      min-height: 101px;
    }
  }
}
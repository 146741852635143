@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.root {
  max-width: 345px;
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-family: $harrybeastregular;
    font-size: 25px;
    line-height: 120%;
    margin-bottom: 30px;
    text-align: center;
  }

  .dobFields {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
    gap: 16px;
    width: 100%;

    .field {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    label {
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    select {
      background: black;
      color: $grey-legal-border;
      font-family: $sofiapro;
      font-size: 16px;
      padding: 14px 60px 14px 14px;
      border-radius: 3px;
      border: 1px solid $darkSilver;

      appearance: none;
      background-image: url('./img/arrow-down.svg');
      background-repeat: no-repeat;
      background-position: right 0.7rem top 50%;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;

    button:first-of-type {
      margin-bottom: 33px;
    }
  }
}


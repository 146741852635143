@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  width: 100%;
  max-width: $component-max-width;
  padding: 0 $component-padding-x;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  
  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  .subHeader {
    font-family: $sofiapro;
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: 0.3px;
    text-align: center;
  }
  
  .quizzes {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
    flex-direction: column;
    
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    
    .quiz {
      width: 100%;
      max-width: 500px;
      margin: auto;
      // overwriting anchorlink style
      margin-bottom: 17px !important;
      display: block;
      
      @include media-breakpoint-up(md) {
        margin-bottom: 0px;
        margin-right: 4%;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .seeAllQuizzes {
    position: absolute;
    bottom: 0px;
    right: 50%;
    transform: translate(50%, 0);
    text-transform: uppercase;
    color: $snow;
    font-family: $sofiapro;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: .02em;
    text-decoration-line: underline;
    font-size: 16px;
    
    @include media-breakpoint-up(md) {
      font-size: 14px;
      right: 30px;
      transform: none;
    }
  }
  
  .desktopOnly {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline;
    }
  }

  .quizInfo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba($black, 0) 50%, $black 100%);
    
    & > div {
      margin-top: auto;
    }
    
    @include media-breakpoint-up(md) {
      padding: 20px;
    }
    
    @include media-breakpoint-up(xm) {
      padding: 25px;
    }
  }

  h3 {
    color: $gold-light;
    font-family: $harrybeastregular;
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 4px;
    line-height: normal;
    
    @include media-breakpoint-up(md) {
      margin: 0;
      font-size: 14px;
      line-height: 17px;
      max-height: 52px;
      align-items: center;
      justify-content: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    
    @include media-breakpoint-up(xm) {
      margin: 0 0 4px;
      font-size: 17px;
      line-height: 18px;
      max-height: none;
      display: flex;
      -webkit-line-clamp: unset;
      overflow: visible;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      height: auto;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .questionCount {
    color: $white;
    font-size: 12px;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
    position: relative;

    @include media-breakpoint-up(md) {
      font-size: 13px;
      top: 0;
    }
  }

  .divider {
    color: rgba($white, 0.60);
    font-size: 16px;
    position: relative;
    bottom: -1px;
    padding: 0 8px;
  }

  .leader {
    width: 48px;
    position: absolute;
    right: 20px;
    top: -2px;
    border-radius: 2px 2px 0 0;
    text-align: center;
    font-family: $sofiapro;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding-top: 41px;
    color: $white;
    border-left: 2px solid red;
    border-right: 2px solid red;
    border-bottom: 2px solid red;

    
    &:before {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 24px 15px 24px;
      border-color: transparent transparent red transparent;
      transform: rotate(180deg);
      position: absolute;
      bottom: -17px;
      left: -2px;
    }
    
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 23px 14px 23px;
      border-color: transparent transparent red transparent;
      transform: rotate(180deg);
      position: absolute;
      bottom: -14px;
      left: -1px;
    }

    @include media-breakpoint-up(lg) {
      width: 56px;
      padding-top: 45px;

      &:before {
        content: '';
        border-width: 0 28px 17px 28px;
        bottom: -19px;
        left: -1px;
      }

      &:after {
        content: '';
        border-width: 0 27px 16px 27px;
        bottom: -16px;
        left: 0px;
      }
    }
    
    &.gryffindor {
      background-color: $gryffindorScoreboard2;
      border-color: $gryffindorSecondary;
      background-image: url('/images/components/FeaturedQuizList/gryffindor.svg');
      background-position: 50% 5px;
      background-size: 26px auto;
      background-repeat: no-repeat;
      
      &:before {
        border-color: transparent transparent $gryffindorSecondary transparent;
      }
      
      &:after {
        border-color: transparent transparent $gryffindorScoreboard2 transparent;
      }

      @include media-breakpoint-up(md) {
        background-size: 30px auto;
      }
    }
    
    &.ravenclaw {
      background-image: url('/images/components/FeaturedQuizList/ravenclaw.svg');
      background-position: 50% 5px;
      background-size: 26px auto;
      background-color: $ravenclawScoreboard;
      border-color: $ravenclawSecondary;
      background-repeat: no-repeat;

      &:before {
        border-color: transparent transparent $ravenclawSecondary transparent;
      }

      &:after {
        border-color: transparent transparent $ravenclawScoreboard transparent;
      }
      
      @include media-breakpoint-up(md) {
        background-size: 30px auto;
      }
    }
    
    &.hufflepuff {
      background-image: url('/images/components/FeaturedQuizList/hufflepuff.svg');
      background-position: 50% 5px;
      background-size: 26px auto;
      background-color: $hufflepuffScoreboard;
      border-color: $hufflepuffSecondary;
      background-repeat: no-repeat;
      color: $darkHufflepuffText;

      &:before {
        border-color: transparent transparent $hufflepuffSecondary transparent;
      }

      &:after {
        border-color: transparent transparent $hufflepuffScoreboard transparent;
      }

      @include media-breakpoint-up(md) {
        background-size: 30px auto;
      }
    }
    
    &.slytherin {
      background-image: url('/images/components/FeaturedQuizList/slytherin.svg');
      background-position: 50% 5px;
      background-size: 26px auto;
      background-color: $slytherinScoreboard;
      border-color: $slytherinSecondary;
      background-repeat: no-repeat;

      &:before {
        border-color: transparent transparent $slytherinSecondary transparent;
      }

      &:after {
        border-color: transparent transparent $slytherinScoreboard transparent;
      }

      @include media-breakpoint-up(md) {
        background-size: 30px auto;
      }
    }
  }

  .coverImageContainer {
    aspect-ratio: 360 / 247;
    position: relative;
    
    img {
      border-radius: 8px;
    }

    .innerBorder {
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 6px;
      right: 6px;
      display: flex;
      justify-content: center;
      border-image-slice: 27 27 27 27;
      border-image-width: 26px 26px 26px 26px;
      border-image-outset: 0px 0px 0px 0px;
      border-image-repeat: stretch stretch;
      border-image-source: url(/images/components/FeaturedQuizList/imageFrame.svg);
      border-style: solid;
      pointer-events: none;
    }
  }

  .stars {
    color: $white;
    font-size: 12px;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
    position: relative;

    .star {
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;
      margin-right: 3px;
      top: 1px;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

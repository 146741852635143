@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background-color: $darkNavy;
  padding: 16px;
  position: relative;
  min-width: 300px;
  min-height: 200px;
  margin: 10px;
}

.container {
  width: 100%;
  height: 100%;
  border: 1px solid $grey-legal-border;
}

.content {
  position: relative;
  padding: 40px;
}

.corner {
  position: absolute;
  width: 42px;
  height: 42px;
  background: $darkNavy;
}

.topLeft {
  top: 9px;
  left: 9px;
}

.topRight {
  transform: rotate(90deg);
  top: 9px;
  right: 7px;
}

.bottomLeft {
  transform: rotate(-90deg);
  bottom: 9px;
  left: 9px;
}

.bottomRight {
  transform: rotate(180deg);
  bottom: 9px;
  right: 7px;
}

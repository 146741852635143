@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$darkThemeText: #2a2c39;
$darkThemeImage: #14161e;
$darkThemeTextContainer: #1c1e28;

$lightThemeText: #ded9cd;
$lightThemeImage: #f1ede6;
$lightThemeTextContainer: #f9f6f0;

@keyframes shine {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.shopRowSkeleton {
  position: relative;
  padding: 10px 60px 60px;
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @include media-breakpoint-down(sm) {
    padding: 10px 0px;
  }

  &.dark {
    .shiningElement {
      background: linear-gradient(
        90deg,
        $darkThemeText 50%,
        rgba(42, 44, 57, 0) 80%,
        $darkThemeText 100%
      );
      background-size: 200% 200%;
      animation: shine 4s linear infinite;
    }
    .itemContainer {
      background: $darkThemeImage;
    }
    .textContainer {
      background: $darkThemeTextContainer;
    }
  }

  &.light {
    .shiningElement {
      background: linear-gradient(
        90deg,
        $lightThemeText 50%,
        rgba(232, 228, 220, 0.6) 77.53%,
        rgba(232, 228, 220, 0) 90%,
        $lightThemeText 100%
      );
      background-size: 200% 200%;
      animation: shine 4s linear infinite;
    }

    .itemContainer {
      background: $lightThemeImage;
    }
    .textContainer {
      background: $lightThemeTextContainer;
    }
  }

  .header {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .left {
      display: flex;
      flex-direction: column;
      flex: 80%;
    }
    .right {
      flex: 20%;
      position: relative;
    }
    .shopLink {
      height: 20px;
      max-width: 223px;
      width: 100%;
      border-radius: 6px;
      position: absolute;
      right: 0px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .title {
      height: 42px;
      max-width: 60%;
      width: 100%;
      border-radius: 9px;
      opacity: 0.7;
      margin-bottom: 20px;

      &:nth-of-type(2) {
        display: none;
        @include media-breakpoint-down(sm) {
          display: block;
          max-width: 80%;
        }
      }
    }

    .description {
      height: 20px;
      max-width: 90%;
      width: 100%;
      border-radius: 6px;
      opacity: 0.7;
      margin-bottom: 8px;

      &:nth-of-type(4),
      &:nth-of-type(5) {
        display: none;
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }
    }
  }

  .itemsCarousel {
    display: flex;
    flex-direction: row;
    gap: 20px;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @extend .shiningElement;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    .itemContainer {
      height: 455px;
      flex: 20%;
      border-radius: 4px;

      &:not(:first-child) {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .image {
        height: 269px;
      }
      .textContainer {
        height: 186px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;

        .line1 {
          height: 11px;
          max-width: 162px;
          width: 100%;
          border-radius: 6px;
          margin-bottom: 14px;
        }
        .line2 {
          height: 10px;
          max-width: 73px;
          width: 100%;
          border-radius: 6px;
          margin-bottom: 46px;
        }
        .line3 {
          height: 30px;
          max-width: 125px;
          width: 100%;
          border-radius: 23px;
        }
      }
    }
  }

  .carouselArrows {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .leftArrow,
      .rightArrow {
        height: 25px;
        width: 25px;
        border-radius: 50%;
      }
      .dots {
        width: 100px;
        height: 10px;
        border-radius: 10px;
      }
    }
  }

  // Single Product View Styles
  &.singleItem {
    margin: 0 auto;
    max-width: 760px;
    .header {
      display: flex;
      flex-direction: column;

      .left,
      .right {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
          align-items: flex-start;
        }
      }

      .shopLink {
        position: relative;
        right: unset;
      }
      .description {
        width: 40%;
        margin-bottom: 20px;
      }
    }


    .itemsCarousel {
      &::before,
      &::after {
        display: none;
      }
    }


    .itemContainer {
      display: flex;
      flex-direction: row;
      height: unset;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      .image {
        flex: 50%;
        height: 380px;
        @include media-breakpoint-down(sm) {
          flex: unset;
        }
      }
      .textContainer {
        padding: 0 50px;
        flex: 50%;
        height: 380px;
        align-items: flex-start;
        @include media-breakpoint-down(sm) {
          align-items: center;
        }

        .line1 {
          &:nth-child(2) {
            margin-bottom: 25px;
          }
        }

        .line2 {
          margin-bottom: 35px;
        }
      }
    }
  }
}

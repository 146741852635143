@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$cloudTiming: 1s;
$overlayTiming: 1.25s;

.root {
  position: relative;
  overflow: hidden;
  
  .overlay {
    position: absolute;
    background: $cloudCover;
    z-index: 6;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
  }

  .cloud {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
    
    &.cloudLeft {
      left: 0;
      background: var(--clouds-l);
      background-size: cover;
      background-position: 100% 0;
      background-repeat: no-repeat;
      transform: translate(300px, 0);
      display: block;

      &:before {
        content: '';
        top: 0;
        left: -99%;
        height: 100%;
        width: 100%;
        background: $cloudCover;
        position: absolute;
      }
    }
    
    &.cloudRight {
      right: 0;
      background: var(--clouds-r);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;
      transform: translate(-300px, 0);
      display: block;

      &:before {
        content: '';
        top: 0;
        right: -99%;
        height: 100%;
        width: 100%;
        background: $cloudCover;
        position: absolute;
      }
    }
  }

  &.animate {
    .overlay {
      animation: overlay calc($overlayTiming/2) ease-in-out 0s 1 forwards;

      @include media-breakpoint-up(md) {
        animation: overlay $overlayTiming ease-in-out 0s 1 forwards;
      }
    }

    .cloudLeft {
      animation: cloudRevealLeft 1.5s ease-in-out 0.25s 1 forwards;
    }
    
    .cloudRight {
      animation: cloudRevealRight 1.5s ease-in-out 0.25s 1 forwards;
    }
  }
}

@keyframes cloudRevealLeft {
  0% {
    transform: translate(300px, 0);
  }
  100% {
    transform: translate(-600px, 0);
  }
}

@keyframes cloudRevealRight {
  0% {
    transform: translate(-300px, 0);
  }
  100% {
    transform: translate(600px, 0);
  }
}

@keyframes overlay {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

$zoomTiming: 1.3s;
$fadeTiming: 0.5s;
$fadeDelay: 1.1s;

.root {
  position: relative;

  .header, .subHeader, .cta {
    opacity: 0;
  }

  .header {
    text-transform: uppercase;
  }

  &.placeholderSpace {
    min-height: 570px;
  }

  .mobileImage {
    position: relative;
    img {
      width: 100%;
      position: relative;
      height: auto;
    }

    &:after {
      content: '';
      background: linear-gradient(360deg, rgba($homeRow1a, 1) 0%, rgba($homeRow1a, 0) 50%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      pointer-events: none;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &.animate {
    animation: zoomOut $zoomTiming ease-in-out 0s 1 forwards;

    .header, .subHeader, .cta {
      animation: fadeIn $fadeTiming ease-in-out $fadeDelay 1 forwards;
    }
  }

  .mobileOnly {
    display: block;
    margin: 0 auto;
    .cta {
      margin: auto;
      background-color: $purple-primary;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .desktopOnly {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .absoluteContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;
  }

  .inner {
    box-sizing: border-box;
    max-width: 550px;
    position: relative;
    height: 100%;
    padding: 25px;
    margin: -40px auto 35px;
    
    @include media-breakpoint-up(md) {
      margin: auto auto 0;
    }

    h2 {
      font-size: 30px;
      margin: 0 0 20px;
      line-height: 106.5%;
      text-shadow: 0 0 20px rgba(0, 0, 0, 1);
    }
    
    p {
      font-size: 16px;
      line-height: 140%;
      margin: 0 0 30px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 1);
    }
  }

  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      background: linear-gradient(360deg, $homeRow1a 40%, rgba($homeRow1a, 0.0) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 150px;
    }

    background: var(--image) 50% 50% no-repeat;
    background-size: cover;
    
    .inner {
      max-width: none;
      justify-content: center;
      height: 550px;
      max-height: 550px;
      box-sizing: border-box;

      .absoluteContent {
        position: absolute;
        bottom: 98px;
        box-sizing: border-box;
        margin-right: $component-padding-x;
      }
      
      h2 {
        max-width: 50%;
        font-size: 50px;
      }
      
      p {
        max-width: 50%;
        font-size: 17px;
        line-height: 123%;
      }
    }

    &.placeholderSpace {
      min-height: 0px;
    }
  }


  @include media-breakpoint-up(lg) {
    background: var(--image) 50% 50% no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .inner {
      padding: 0 $component-padding-x 0;
      max-width: $component-max-width;

      h2 {
        max-width: 50%;
        font-size: 50px;
      }
      
      p {
        max-width: 48%;
        font-size: 18px;
      }
    }
  }

  &.left {
    @include media-breakpoint-up(md) {
      .absoluteContent {
        text-align: left;
        align-items: flex-start;
      }
    }
  }

  &.right {
    @include media-breakpoint-up(md) {
      .absoluteContent {
        text-align: right;
        align-items: flex-end;
      }
    }
  }

  &.center {
    @include media-breakpoint-up(md) {
      .absoluteContent {
        text-align: center;
        align-items: center;
      }
    }
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

$greyDisabled: #8C8C8C;
$optionHoverBgColor: rgba($lightMagic, 0.5);

@keyframes fillBackground {
  0% {
    width: 0%;
    padding: 15px 0;
  }
  100% {
    width: 100%;
    padding: 15px 30px;
  }
}

@keyframes progressFill {
  0% {
    width: 0%;
  }
  100% {
    width: var(--percentage);
  }
}

.root {
  background-image: url('./imgs/poll-frame.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // TODO: Remove
  max-width: 520px;
  width: 100%;

  @include media-breakpoint-up(lg) {
    height: calc(100% - #{$content-file-footer-height});
  }

  &.isInView {
    .options {
      .option {
        animation: fillBackground 1s forwards;
        animation-delay: 0.1s;
      }
      .option:nth-child(2) {
        animation-delay: 0.2s;
      }
      .option:nth-child(3) {
        animation-delay: 0.3s;
      }
      .option:nth-child(4) {
        animation-delay: 0.4s;
      }
    }

    .results {
      .progress {
        animation: progressFill 1s forwards;
      }
    }
  }

  .textContainer {
    padding: 40px;
    @include media-breakpoint-down(md) {
      padding: 40px 28px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $snow;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-family: $sofiapro;
    font-size: 14px;
    margin-bottom: 12px;

    @include media-breakpoint-down(lg) {
      font-size: 11.276px;
    }

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 2px;
      margin: 10px;
      background: $snow;
    }
  }

  .question {
    font-family: $harrybeastregular;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: $snow;
    margin-bottom: 68px;

    @include media-breakpoint-down(lg) {
      font-size: 24.5px;
      line-height: 32px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 50px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 7px;
    .option {
      font-family: $sofiapro;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: $snow;
      // Dark magic 40% opacity
      background-color: rgba(11, 22, 59, 0.4);
      padding: 15px 0;
      border-radius: 10px;
      border: 1px solid $lightMagic;
      text-align: center;
      cursor: pointer;
      width: 0;
      overflow: hidden;
      white-space: nowrap;

      &:hover {
        background-color: $optionHoverBgColor;
      }
      &:active {
        background-color: $snow;
      }

      @include media-breakpoint-down(lg) {
        font-size: 16.378px;
      }

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }
  }

  .results {
    .option {
      margin-bottom: 25px;
      &.selected {
        .optionTextContainer {
          color: $snow;
        }
        .progress {
          background-color: $snow;
        }
      }
    }

    .optionTextContainer {
      display: flex;
      justify-content: space-between;
      font-family: $sofiapro;
      font-weight: bold;
      font-size: 18px;
      color: $greyDisabled;
      text-transform: uppercase;
      margin-bottom: 5px;

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }

    .percentage {
      margin-left: 10px;
    }

    .progressBarContainer {
      width: 100%;
      background-color: #161621;
      height: 10px;
      border-radius: 10px;

      .progress {
        width: 0;
        background-color: $greyDisabled;
        height: 10px;
        border-radius: 10px;
      }
    }
  }

  .answerSkeleton {
    display: flex;
    flex-direction: column;
    gap: 7px;
    .answerItem {
      background-color: $darkMagic;
      height: 60px;
      border-radius: 10px;
      border: none;
      width: 100%;
    }
  }

  .cta {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    button, .ctaLabel {
      font-size: 16px;
      text-transform: none;
      letter-spacing: 0.32px;
      font-weight: 600;
      line-height: normal;
    }
  }
}

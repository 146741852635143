@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$darkThemeText: $snow;
$lightThemeText: $ww-black;

.shopRow {
  position: relative;
  max-width: $component-max-width;
  margin: 0 auto;
  width: 100%;

  // Dark theme styles
  &.dark {
    .shopLink > a {
      color: $darkThemeText;
      text-decoration-color: $darkThemeText !important;
    }

    .shopUnavailable {
      background: rgba($darkNavy, 0.7);
      color: $snow;
    }
  }

  // Light theme styles
  &.light {
    .shopLink {
      justify-content: center;
      & > a {
        color: $lightThemeText;
        text-decoration-color: $lightThemeText !important;
      }
    }

    .disabledOverlay {
      bottom: 100px;
    }

    .shopUnavailable {
      background: rgba($snow, 0.7);
      color: $darkNavy;
    }
  }

  .header {
    padding: 0 25px;
  }

  .shopLink {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    top: -115px;
    margin-right: 25px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      justify-content: center;
      margin-right: 0;
    }
    // Few exceptions from standard breakpoints to adjust according to shop row
    @media only screen and (max-width: 626px) {
      top: 60px;
    }

    @media only screen and (max-width: 600px) {
      top: -75px;
    }

    @include media-breakpoint-down(sm) {
      top: 80px;
    }

    a {
      text-decoration-line: underline;
    }
  }

  .invisibleOverlayWrapper {
    position: relative;
  }

  .invisibleOverlay {
    position: absolute;
    top: 0;
    left: -100px;
    width: calc(100% + 200px);
    height: 100%;
    z-index: 1;
    cursor: pointer;

    @media only screen and (max-width: 1620px) {
      left: 0;
      width: 100%;
    }

    @media only screen and (max-width: 626px) {
      height: calc(100% + 90px);
    }
    &.disabled {
      width: 0;
      height: 0;
      z-index: -1;
    }
  }

  // Shop Disabled styles
  &.disabled {
    max-width: unset;
    margin: unset;

    .contentWrapper {
      max-width: $component-max-width;
      margin: 0 auto;
    }
  }

  .disabledOverlay {
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
    backdrop-filter: blur(30px);
    z-index: 1;
  }

  .shopUnavailable {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 458px;
    box-sizing: border-box;
    padding: 52px 67px 69px 67px;
    font-family: $harrybeastregular;
    font-size: 28px;
    text-align: center;
    line-height: 120%;
    border-radius: 4px;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      padding: 47px 41px 55px 41px;
      width: 311px;
    }

    img {
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        width: 52px;
      }
    }
  }
}
